.carousel {
    min-height: 100vh;
    display: flex;

    .carousel-inner {
        min-height: 100%;
    }

    .carousel-item {
        height: 100%;
        padding-top: 160px;
        position: relative;
        overflow: hidden;
        transition: $transition;
        transition-duration: 1s;

        @include screen(1700) {

            &:not(.home) {

                &:before {
                    background-size: 100% !important;
                }
            }
        }

        @include screen(768) {

            &:not(.home) {

                &:before {
                    display: none;
                }
            }
        }

        @include screen(1280) {
            padding-top: 50px;
        }

        @include screen(768) {
            padding-top: 82px;
        }

        &:before {
            content: '';
            font-family: 'Nunito Sans', Arial, Tahoma, sans-serif;
            width: 100%;
            height: 100%;
            white-space: nowrap;
            display: flex;
            align-items: center;
            background-repeat: no-repeat;
            background-position-y: center;
            position: absolute;
            left: 0;
            top: 0;
            font-weight: 900;
            font-size: 31.3vw;
            line-height: 1;
            color: #220e0e;
            transition: $transition;
            z-index: 1;
        }
    }

    &__item {

        &-inner {
            height: 100%;
            display: flex;
            position: relative;
            z-index: 10;

            @include screen(768) {
                justify-content: flex-start;
                padding-bottom: 48px;
            }

            h1 {
                font-weight: 800;
                font-size: 120px;
                line-height: 164px;
                margin: 0 0 100px;

                @include screen(1280) {
                    font-size: 90px;
                    line-height: 112px;
                    margin: 0 0 80px;
                }

                @include screen(1024) {
                    font-size: 74px;
                    line-height: 82px;
                }

                @include screen(768) {
                    font-size: 46px;
                    line-height: 52px;
                    margin-bottom: 64px;
                }
            }
        }

        &-row {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            align-items: center;
            height: 100%;

            @include screen(1700) {
                justify-content: space-between;
            }
        }

        &-col {
            width: calc(50% - 90px);

            @include screen(1280) {
                width: calc(50% - 30px);
            }

            @include screen(768) {
                width: 100%;
            }

            p {

                & + p {
                    margin-top: 24px;
                }
            }

            h2 {
                font-weight: 800;
                font-size: 48px;
                line-height: 65px;
                margin-bottom: 68px;

                @include screen(1280) {
                    margin: 0 0 80px;
                    line-height: 52px;
                }

                @include screen(768) {
                    display: none;
                    font-size: 46px;
                }
            }

            .button {
                margin-top: 100px;

                @include screen(768) {
                    margin-top: 64px;
                }
            }
        }

        &-image {
            text-align: right;

            @include screen(1700) {
                text-align: center;
            }

            @include screen(768) {
                order: -1;

                img {
                    max-width: 90%;
                }
            }

            h2 {
                display: none;

                @include screen(768) {
                    display: block;
                    text-align: left;
                    margin-bottom: 32px;
                }
            }
        }
    }

    &__item-text {
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;

        p {
            margin-top: 64px;
            text-align: justify;
            max-width: 720px;

            @include screen(768) {
                margin-top: 32px;
                max-width: none!important;
            }

            .link {
                cursor: pointer;
                color: $yellow;
                transition: $transition;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        span {
            font-weight: 600;
        }
    }

    .home {

        &:before {
            background-image: url("../images/logo_home.svg");
            background-position-x: 94%;
            background-size: auto calc(100% - 160px);

            @include screen(1280) {
                background-size: 420px auto;
                background-position-y: 97%;
            }

            @include screen(768) {
                background-size: 215px;
            }
        }

        &.active {

            @include screen-min(769) {
                &.carousel-item-start {

                    &:before {
                        animation: translate-right-home 2s;
                    }
                }
            }
        }

        .carousel__item-inner {
            flex-direction: column;
            justify-content: center;
        }

        .carousel__item-text {

            p {
                max-width: 420px;
            }
        }
    }

    .about {

        &:before {
            content: 'ABOUT';
            left: 3%;
        }

        &.active {

            @include screen-min(769) {

                &.carousel-item-start {

                    &:before {
                        animation: translate-right-about 2s;
                    }
                }
            }
        }
    }

    .offers {

        &:before {
            content: 'ABOUT US';
            right: 82%;
            left: auto;
        }

        &.active {

            @include screen-min(769) {

                &.carousel-item-start {

                    &:before {
                        animation: translate-right-offers 2s;
                    }
                }
            }
        }
    }
}

@keyframes translate-right-home {
    from {
        background-position-x: 94%;

    }
    to {
        background-position-x: 103%;
    }
}

@keyframes translate-right-about {
    from {
        left: 3%;

    }
    to {
        left: 15%;
    }
}

@keyframes translate-right-offers {
    from {
        right: 82%;

    }
    to {
        right: 70%;
    }
}
