.services {

    .modal-header {
        margin-bottom: 40px;

        @include screen(768) {
            margin-bottom: 0;
        }
    }

    .modal-body {
        max-width: 1620px;

        @include screen(768) {
            padding-top: 80px;
        }
    }

    &__body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &-item {
            width: calc(50% - 90px);
            padding: 32px 0;

            @include screen(1024) {
                width: calc(50% - 40px);
                padding: 24px 0;
            }

            @include screen(768) {
                width: 100%;
                padding: 12px 0;
            }

            img {
                max-width: 80px;
                margin: 0 0 30px;

                @include screen(768) {
                    margin-bottom: 24px;
                }
            }

            h4 {
                color: $black;
                font-weight: 600;
                font-size: 21px;
                line-height: 29px;
                margin: 0 0 24px;

                @include screen(768) {
                    margin-bottom: 16px;
                }
            }

            p {
                color: $black;
                font-weight: 300;
                font-size: 18px;
                line-height: 25px;
                text-align: justify;
            }
        }
    }
}
