.modal {
    z-index: 100;
    color: $black;

    &-backdrop {
        background-color: $white;

        &.show {
            opacity: 1;
            z-index: 25;
        }
    }

    &-fullscreen {

        .modal-content {
            background: none;
            padding: 72px 0 0;

            @include screen(1280) {
                padding: 48px 0 0;
            }

            @include screen(768) {
                padding: 0;
            }
        }

        .modal-header {
            z-index: 10;
            border: none;
            padding: 0;
            justify-content: flex-end;
            position: absolute;
            top: 64px;
            right: 75px;

            @include screen(1280) {
                top: 48px;
                right: 45px;
            }

            @include screen(768) {
                border-bottom: 1px solid $semi_black;
                background: rgba(255,255,255,.8);
                backdrop-filter: blur(10px);
                height: 48px;
                padding: 10px;
                top: 0;
                right: 0;
                width: 100%;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 33px;
            }

            .button-close {
                background: none;
                border: none;
                outline: none;
                cursor: pointer;
                line-height: 0;
                align-self: flex-start;

                @include screen(768) {
                    line-height: inherit;
                    margin-left: 4px;
                }

                @include screen(400) {
                    margin-left: 0;
                }
            }

            .button-close-icon {
                padding: 0;
                display: inline-block;
                width: 35px;
                height: 35px;
                background: url("../images/cross.svg") no-repeat 50%;
                background-size: 100%;
                transition: $transition;
                z-index: 21;

                span {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 25px;
                    color: $black;
                    display: none;
                    position: relative;
                    padding-left: 30px;

                    &:before {
                        content: '';
                        width: 20px;
                        height: 12px;
                        background: url("../images/arrow_left.svg") no-repeat 50%;
                        background-size: 100%;
                        position: absolute;
                        left: 0;
                        top: calc(50% - 6px);
                    }
                }

                @include screen(768) {
                    width: auto;
                    height: auto;
                    background: none;

                    span {
                        display: inline-block;
                    }
                }

                @include screen-min(769) {
                    &:hover {
                        transform: rotate(-90deg);
                    }
                }
            }
        }

        .modal-body {
            max-width: 1500px;
            padding: 0 75px;

            @include screen(1280) {
                padding: 0 45px;
            }

            @include screen(768) {
                padding: 80px 45px 0;
            }

            @include screen(400) {
                padding: 80px 10px 0;
            }

            h2 {
                font-weight: 800;
                font-size: 48px;
                line-height: 65px;
                margin: 0;
                margin-bottom: 48px;

                @include screen(768) {
                    line-height: 54px;
                    margin-bottom: 32px;
                }
            }

            .text {
                font-weight: 300;
                font-size: 21px;
                line-height: 29px;
            }

            p {
                font-weight: 300;
                font-size: 18px;
                line-height: 25px;
                text-align: justify;

                @include screen(1280) {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }
    }
}
