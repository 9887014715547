.header {
    padding: 50px 45px 0;
    position: relative;
    transition: $transition;

    @include screen-min(1801) {
        padding: 50px 75px 0;
    }

    @include screen(1280) {
        padding: 25px 45px;
    }

    @include screen(768) {
        border-bottom: 1px solid $semi_white;
        justify-content: flex-end!important;
        height: 60px;
        padding: 15px;
        background: rgba(0,0,0,.8);
        backdrop-filter: blur(10px);

        .navbar-collapse {

            &.opened {

                & + .legal-mentions {
                    display: block;
                    transform: none;
                    position: absolute;
                    bottom: 20px;
                }
            }
        }
    }

    @include screen(400) {
        height: 50px;
    }

    @include screen(400) {
        padding: 7px 10px;
    }
    
    &__logo {
        cursor: pointer;

        @include screen(768) {
            max-width: 25px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .navbar-toggler {
        border: none;
        outline: none;
        box-shadow: none;
        position: relative;
        display: flex;
        align-items: center;
        padding: 0;
        width: 48px;
        height: 35px;

        &:before,
        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            width: 45px;
            height: 5px;
            background: $white;
            transform: rotate(-45deg) translate(4px, -3px);
            transition: $transition;

            @include screen(768) {
                height: 2px;
                width: 20px;
                transform: rotate(-45deg) translate(1px, -2px)
            }
        }

        &:after {
            transform: rotate(45deg);
            top: auto;
            bottom: 50%;
        }

        &.collapsed {

            &:before,
            &:after {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                width: 100%;
                height: 5px;
                background: $white;
                transform: none;
                transition: $transition;

                @include screen(768) {
                    height: 2px;
                }
            }

            &:after {
                top: auto;
                bottom: 0;
                width: 36px;

                @include screen(768) {
                    width: 10px;
                }
            }

            &:hover {

                .navbar-toggler-icon {

                    @include screen-min(1025) {
                        background: $yellow;
                    }
                }

                &:after {
                    width: 100%;
                }
            }

            .navbar-toggler-icon {
                width: 100%;
                height: 5px;
                background: $white;

                @include screen(768) {
                    height: 2px;
                }
            }
        }

        &:hover {

            @include screen-min(1025) {

                &:before,
                &:after {
                    background: $yellow;
                }
            }
        }

        .navbar-toggler-icon {
            position: relative;
            background: none;
            border: none;
            outline: none;
            transition: $transition;
        }

        @include screen(768) {
            width: 20px;
            height: 14px;
        }
    }
}

#navigaton {
    position: fixed;
    padding: 0;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 11;

    &.black {
        background: $dark;

        @include screen(768) {
            background: $dark_black;
        }
    }

    .navbar-collapse {
        background: $dark url("../images/menu_bg.svg") no-repeat 20% 50%;
        position: fixed;
        left: 0;
        top: 110px;
        width: 100%;
        height: calc(100vh - 110px);
        z-index: 20;

        @include screen(1280) {
            top: 110px;
            height: calc(100vh - 110px);
        }

        @include screen(1024) {
            background-size: 86%;
        }

        @include screen(768) {
            top: 60px;
            height: calc(100vh - 50px);
            background-image: none;
            background-color: $dark_black;
        }

        @include screen(400) {
            top: 50px;
        }

        .legal-mentions {

            @include screen(768) {
                display: block;
                transform: translateX(-50%);
                position: absolute;
                bottom: 20px;
                left: 50%;
                text-align: center;

                &:after {
                    display: none;
                }
            }
        }

        .container {
            height: 100%;
            display: flex;
            align-items: center;
            width: 100%;
            opacity: 0;
            transition: .5s;

            @include screen(768) {
                display: block;
                padding-bottom: 60px;
            }

            a {
                font-weight: 800;
                font-size: 48px;
                line-height: 54px;
                color: $semi_white;
                display: inline-block;
                width: calc(50% - 100px);
                margin: 32px 0;
                cursor: pointer;
                transition: $transition;

                @include screen(1280) {
                    font-size: 38px;
                    line-height: 44px;
                    margin: 25px 0;
                }

                @include screen(1024) {
                    font-size: 32px;
                    line-height: 38px;
                    margin: 22px 0;
                    width: calc(50% - 30px);
                }

                @include screen(768) {
                    width: 100%;
                    text-align: center;
                }

                @include screen(480) {
                    font-size: 24px;
                    line-height: 33px;
                    margin: 16px 0;
                }

                &:nth-child(2n) {
                    padding-left: 24px;

                    @include screen(768) {
                        padding: 0;
                        margin: 20px 0;
                    }
                }

                &:hover {
                    color: $white;
                }
            }
        }

        .navbar-links {
            width: 100%;

            @include screen(768) {
                padding-top: 50px;
            }
        }

        &.opened {

            .container {
                opacity: 1;
            }
        }
    }
}
