@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;600;800;900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    font-size: 16px;
    text-decoration: none;
    transition: .3s;
}

img {
    max-width: 100%;
    height: auto;
}

::-webkit-input-placeholder {
    font-style: italic;
}

::-moz-placeholder {
    font-style: italic;
}

:-ms-input-placeholder {
    font-style: italic;
}

:-moz-placeholder {
    font-style: italic;
}

::placeholder {
    font-style: italic;
}

html {
    height: -webkit-fill-available;
}

body {
    margin: 0;
    font-family: 'Nunito Sans', Arial, Tahoma, sans-serif;
    font-weight: 300;
    font-size: 18px;
    color: $white;
    min-height: -webkit-fill-available;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: $dark;

    &.no-scroll {
        overflow: hidden;
    }
}

ul {
    list-style: none;
    padding: 0;
}

.container {
    max-width: 1800px;
    padding: 0 45px;
    position: relative;

    @include screen(400) {
        padding: 0 10px;
    }
}

.button {
    width: 100%;
    max-width: 420px;
    height: 50px;
    position: relative;
    font-weight: 600;
    font-size: 24px;
    line-height: 1;
    display: inline-flex;
    background: none;
    border: none;
    text-align: left;
    color: $white;
    transition: $transition;

    @include screen(768) {
        max-width: 320px;
    }

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        background: $yellow;
        width: calc(100% - 88px);
        transition: $transition;

        @include screen(768) {
            width: calc(100% - 30px);
        }
    }

    &:before {
        content: '';
        width: 68px;
        height: 12px;
        background: url("../images/button_arrow.svg") no-repeat 50%;
        background-size: 100%;
        position: absolute;
        top: 6px;
        right: 20px;
        transition: $transition;

        @include screen(768) {
            right: 0;
        }
    }

    &:hover {
        color: $yellow;

        &:after {
            width: 100%;
        }

        &:before {
            right: 0;
            background-image: url("../images/button_arrow_yellow.svg");
        }
    }
}

.legal-mentions {
    font-weight: 300;
    font-size: 14px;
    line-height: 1;
    color: $semi_white;
    transition: $transition;
    height: 26px;
    display: flex;
    position: fixed;
    transform: rotate(-90deg);
    right: 25px;
    bottom: 100px;
    cursor: pointer;
    z-index: 20;

    @include screen(1800) {
        right: -2px;
    }

    @include screen(768) {
        display: none;
    }

    &:after {
        content: '';
        width: 0;
        height: 2px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: $yellow;
        transition: $transition;
    }

    &:hover {
        color: $white;

        &:after {
            width: 100%;
        }
    }
}
